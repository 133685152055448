import React, { useState } from "react"
import HeaderHead from "./HeaderHead";
import Container from "react-bootstrap/Container";
import { graphql, Link, useStaticQuery } from "gatsby";
import { MDXProvider } from "@mdx-js/react"
import Table from 'react-bootstrap/Table'
import { Helmet } from "react-helmet";
import { Button, Collapse } from "react-bootstrap";

function DocsLayout({ children, location, title = null }) {
    const [open, setOpen] = useState(false);
    const data = useStaticQuery(graphql
        `query DocsQuery {
            allMdx(sort: {fields: frontmatter___order}) {
                group(field: frontmatter___group) {
                    field
                    fieldValue
                    nodes {
                        slug
                        id
                        tableOfContents
                        frontmatter {
                            nav_title
                            group
                            title
                            order
                        }
                    }
                }
                nodes {
                    tableOfContents(maxDepth: 3)
                    frontmatter {
                        title
                        order
                        group
                        nav_title
                        lead
                    }
                    slug
                }
            }
        }`
    )

    let toc = [];
    let frontmatter = {}

    data.allMdx.nodes.forEach(node => {
        if ('/docs/' + node.slug + '/' === location.pathname) {
            toc = node.tableOfContents.items
            frontmatter = node.frontmatter
        }
    })

    frontmatter.pageTitle = "Firstparty - " + frontmatter.title || title;
    let nextnode = null;
    data.allMdx.group.forEach(group => {
        group.nodes.forEach((node, i) => {
            if ('/docs/' + node.slug + '/' === location.pathname) {
                if (Boolean(group.nodes[i + 1])) {
                    nextnode = group.nodes[i + 1];
                }
            }
        })
    })

    let groups = [];
    let remains = [];
    let order = [
        'Overview',
        'Workflows',
        'Data Objects',
        'Segments',
        'Audiences',
        'Sources',
        'Destinations',
        'Rules',
        'Conditions',
        'Actions',
        'Connections',
        'Properties',
    ];

    data.allMdx.group.forEach((group, i) => {
        if (order.indexOf(group.nodes[0].frontmatter.group) < 0) {
            remains.push(group);
        }
        else {
            groups[order.indexOf(group.nodes[0].frontmatter.group)] = group;
        }
    })

    groups = [...groups];

    return (
        <div>
            <Helmet>
                <title>{frontmatter.pageTitle}</title>
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
                <html className="bg-light" />
                <body className="header-offset bg-light" />
            </Helmet>
            <HeaderHead fluid={true} fixed={true} />
            <nav className="d-lg-none bg-dark">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col">

                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <span className="text-white">
                                        Docs
                                    </span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="text-white">
                                        {frontmatter.group}
                                    </span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span className="text-white">
                                        {frontmatter.nav_title}
                                    </span>
                                </li>
                            </ol>
                        </div>
                        <div className="col-auto">
                            <div className="navbar-dark">
                                <Button className="navbar-toggler" onClick={() => setOpen(!open)}
                                    aria-controls="sidenavCollapse"
                                    aria-expanded={open}>
                                    <span className="navbar-toggler-icon"></span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <section>
                <Container fluid={true}>
                    <div className="row">
                        <div className="col-12 col-lg-3 col-xl-2 px-lg-0 border-bottom border-bottom-lg-0 border-end-lg border-gray-300 sidenav sidenav-start">
                            <Collapse className="d-lg-block" id="sidenavCollapse" in={open}>
                                <div className="py-7 py-lg-9 px-lg-7">
                                    {groups.map(group => {
                                        return <div key={group.fieldValue} className="mb-6">
                                            <h6 className="text-uppercase fw-bold">{group.fieldValue}</h6>
                                            <ul className="list">
                                                {group.nodes.map(node => {
                                                    return <li key={node.id} className="list-item">
                                                        <Link to={'/docs/' + node.slug + '/'} onClick={() => setOpen(false)} href="#" className={('/docs/' + node.slug === location.pathname || '/docs/' + node.slug + '/' === location.pathname ? "active " : "") + "list-link"}>
                                                            {node.frontmatter.nav_title}
                                                        </Link>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    })}
                                </div>
                            </Collapse>
                        </div>
                        <div className="documentation-content col-12 col-lg-6 col-xl-8 offset-lg-3 offset-xl-2 py-7 py-lg-9 px-lg-7">
                            <div className="row h-100 d-flex flex-row">
                                <div className="col-12 align-self-start">
                                    <h1 className="fw-light text-muted fs-1">{frontmatter.title || title}</h1>
                                    <p className="lead mb-0">{frontmatter.lead}</p>
                                    <hr className="my-6 my-md-5 border-gray-300"/>
                                    <MDXProvider
                                        components={{
                                            table: Table,
                                        }}
                                    >
                                        {children}
                                    </MDXProvider>
                                </div>
                                <div className="col-12 align-self-end d-flex flex-row align-items-end w-100 pb-5">
                                    <div className="w-100">
                                        <hr />
                                        {
                                            Boolean(nextnode) ?
                                                <Link to={'/docs/' + nextnode.slug} className="float-end next-link">Next: {nextnode.frontmatter.title} →</Link>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-xl-2 d-none d-lg-block px-lg-0 sidenav sidenav-end">
                            <div className="my-7 my-lg-9 px-lg-7 border-start-lg border-gray-300">
                                <div className="toc">
                                    <h6 className={Boolean(toc) && toc.length > 0 ? "text-uppercase d-none d-sm-block text-muted" : "d-none"}>On This Page</h6>

                                    {Boolean(toc) && toc.length > 0 ? toc.map(item => {
                                        return <ul className="list mb-2" key={item.url}>
                                            <li className="list-item">
                                                <a href={item.url} className="list-link">{item.title}</a>
                                            </li>
                                            {Boolean(item.items) ? item.items.map(subitem => {
                                                return <li key={subitem.url} className="list-item">
                                                    <a href={subitem.url} className="sublink list-link">{subitem.title}</a>
                                                </li>
                                            }) : ""}
                                        </ul>
                                    }) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default DocsLayout